<template>
    <div class="pagePadding">
        <span class="titleTxt">仓库名称：{{detailsInfo.warehouse_name}}</span>
        <div class="pageTop">
            <span class="titleTxt">{{order_type == 2 ? '报损' : '报溢'}}清单</span>
            <Table :columns="listColumns" :data="meterList" border no-data-text="暂无产品">
            </Table>
            <div v-if="meterList.length == 0" class="summary picking">暂未报溢清单</div>
        </div>
        <div class="pageTop">
            <div class="inputFlex">
                <span class="titleTxt">待入库清单</span> <span class="fr pageBtn finger btnSure marginLeft20" @click="setupStatus = true">设置</span>
            </div>
            <TableColumns :productList="stayListColumns" :productData="meterList2" :option_page="option_page" border no-data-text="暂无产品">
                <!-- 供应商 -->
                <template slot-scope="{ row, index }" slot="supplier_id">
                    <div class="inputFlex">
                        <Select style="text-align:left;" placeholder="请选择" v-model="row.supplier_id" clearable filterable class="iviewIptWidth250" @on-change="changeIpt($event,'supplier_id',index)">
                            <Option v-for="(item, index) in row.supplier_list" :value="item.supplier_id" :key="index">{{ item.supplier_name }}</Option>
                        </Select>
                    </div>
                </template>
                <!-- 客户下拉 -->
                <template slot-scope="{ row, index }" slot="customer_name">
                    <div class="inputFlex">
                        <Select style="text-align:left;" placeholder="请选择" v-model="row.customerIndex" clearable filterable class="iviewIptWidth250" @on-change="changeIpt($event,'customerIndex',index)">
                            <Option v-for="(item, index) in customIdList" :value="index" :key="index">{{ item.customer_name }}</Option>
                        </Select>
                    </div>
                </template>
                <!-- 批号 -->
                <template slot-scope="{ row, index }" slot="batch_number">
                    <div class="inputFlex">
                        <Input placeholder="请输入" :value="row.batch_number" @on-change="changeIpt($event, 'batch_number', index)"></Input>
                    </div>
                </template>
                <!-- 序列号 -->
                <template slot-scope="{ row, index }" slot="serial_number">
                    <div class="inputFlex">
                        <i-input placeholder="请输入" :value="row.serial_number" @on-change="changeIpt($event, 'serial_number', index)"></i-input>
                    </div>
                </template>
                <!-- 采购单价 -->
                <template slot-scope="{ row, index }" slot="unit_price">
                    <div class="inputFlex">
                        <InputNumber :max="999999" :min="0" :precision="2" v-model="row.unit_price" :active-change="false" @on-change="changeIpt($event, 'unit_price', index)"></InputNumber>
                        <!-- <i-input placeholder="请输入" :value="row.unit_price" @on-change="changeIpt($event, 'unit_price', index)"></i-input> -->
                        <span>￥</span>
                    </div>
                </template>
                <!-- 销售单价 -->
                <template slot-scope="{ row, index }" slot="sale_unit_price">
                    <div class="inputFlex">
                        <InputNumber :max="999999" :min="0" :precision="2" v-model="row.sale_unit_price" :active-change="false" @on-change="changeIpt($event, 'sale_unit_price', index)"></InputNumber>
                        <!-- <i-input placeholder="请输入" :value="row.sale_unit_price" @on-change="changeIpt($event, 'sale_unit_price', index)"></i-input> -->
                        <span>￥</span>
                    </div>
                </template>
                <!-- 数量 -->
                <template slot-scope="{ row, index }" slot="quantity">
                    <div class="inputFlex">
                        <InputNumber :max="999999" :min="0" :precision="0" v-model="row.quantity" :active-change="false" @on-change="changeIpt($event, 'quantity', index,row.product_name,row.product_model_code,row.identification)"></InputNumber>
                    </div>
                </template>
                <!-- 生产日期 -->
                <template slot-scope="{ row, index }" slot="production_date">
                    <div class="inputFlex">
                        <Input @on-blur="dataBlur($event, true)" :value="row.production_date" placeholder="请输入" style="width: 200px;" @on-change="changeIpt($event, 'production_date', index)" />
                    </div>
                </template>
                <!-- 有效期/失效期 -->
                <template slot-scope="{ row, index }" slot="valid_period">
                    <div class="inputFlex">
                        <Input @on-blur="dataBlur($event, true)" :value="row.valid_period" placeholder="请输入" style="width: 200px;" @on-change="changeIpt($event, 'valid_period', index)" />
                    </div>
                </template>
            </TableColumns>
            <div v-if="meterList2.length == 0" class="summary picking">暂未待入库清单</div>
        </div>
        <div class="pageBtm clearfix">
            <span class="pageBtn finger btnSure fr marginLeft20" @click="comfiy">提交</span>
            <span class="pageBtn finger btnReset fr" @click="back">返回</span>
        </div>
        <!-- 动态表头弹窗 -->
        <table-setup v-if="setupStatus" :pageList="titleList" :option_page="option_page" @cancleBtn="setupStatus = false" @sureBrn="sureSetup"></table-setup>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TableSetup from '@/components/tableSetup'
import TableColumns from '@/components/table'
export default {
    name: 'inventoryStockToExaminsConsign',
    components: {
        TableSetup,
        TableColumns
    },
    computed: {
        ...mapState(['clientNameList']),
    },
    data() {
        return {
            id: '',
            order_type: '',
            // 供应商下拉
            supplierSelect: [],
            // 客户下拉
            customIdList: [],
            setupStatus: false,
            detailsInfo: {},
            storeList: [],
            meterList: [], // 已选产品列表
            listColumns: [
                {
                    title: '序号',
                    type: 'index',
                    align: 'center',
                    width: 65,
                },
                {
                    title: '产品编号',
                    key: 'product_model_code',
                    align: 'center',
                    minWidth: 180,
                },
                {
                    title: '产品名称',
                    key: 'product_name',
                    align: 'center',
                    minWidth: 150,
                },
                {
                    title: '规格型号',
                    key: 'model_name',
                    align: 'center',
                    width: 200,
                },
                {
                    title: '采购单价',
                    key: 'unit_price',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '数量',
                    key: 'quantity',
                    align: 'center',
                    minWidth: 100,
                },
                {
                    title: '入库数量',
                    key: 'receipt_quantity',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '小计',
                    key: 'subtotal',
                    align: 'center',
                    width: 150,
                },
                {
                    title: '单位',
                    key: 'unit',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '操作',
                    align: 'center',
                    width: 200,
                    render: (h, param) => {
                        return h('div', [
                            h(
                                'span',
                                {
                                    style: {
                                        color: '#389AFC',

                                        cursor: 'pointer',
                                    },
                                    on: {
                                        click: () => {
                                            // this.goDetails(param.row)
                                            this.synchronization(param.row, param.index)
                                        },
                                    },
                                },
                                '同步'
                            ),
                        ])
                    },
                },
            ],
            titleList: [],
            meterList2: [], // 同步产品
            stayListColumns: [
                {
                    title: '序号',
                    type: 'index',
                    align: 'center',
                    width: 65,
                },
                {
                    title: '供应商',
                    slot: 'supplier_id',
                    align: 'center',
                    minWidth: 180,
                },
                {
                    title: '产品编号',
                    slot: 'product_model_code',
                    align: 'center',
                    minWidth: 150,
                },
                {
                    title: '产品名称',
                    slot: 'product_name',
                    align: 'center',
                    minWidth: 150,
                },
                {
                    title: '规格型号',
                    slot: 'model_name',
                    align: 'center',
                },
                {
                    title: '采购单价',
                    slot: 'unit_price',
                    align: 'center',
                },
                {
                    title: '批号',
                    key: 'serial_number',
                    align: 'center',
                },
                {
                    title: '生产日期',
                    slot: 'production_date',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '有效日期',
                    slot: 'valid_period',
                    align: 'center',
                    width: 100,
                },
            ],
            option_page: '36',
        }
    },
    computed: {
        ...mapState(['supplierNameList', 'clientNameList']),
    },
    mounted() {
        this.id = this.$route.query.id
        this.order_type = this.$route.query.order_type
        // 判断报溢还是报损
        if (this.order_type == 2) {
            this.option_page = '37'
        }
        this.queryInventoryDetail()
        this.sureSetup()
        this.getSupple()
        this.getCustomerSelect()
    },
    methods: {
        // 客户下拉
        getCustomerSelect() {
            // 获取客户下拉
            this.$http.get(this.$apiConsign.warehousePickingListCustomer, true).then(res => {
                this.customIdList = res.data
            })
        },
        // 生产日期有效期 校验
        dataBlur(e, testing) {
            if (testing && !e.target.value) return
            let res = this.$utils.dataType(e.target.value)
            if (res.code) {
                this.$Message.warning(res.text)
            }
        },
        // 输入框改变
        changeIpt(e, key, index, product_name, product_code, identification) {
            if (typeof e === 'object' && e !== null) {
                e = e.target.value
            }
            // 客户下拉需要转换
            if (key == 'customerIndex') {
                let customer_id = this.clientNameList[e].customer_id
                let customer_type = this.clientNameList[e].customer_type
                this.$set(this.meterList2[index], 'customer_id', customer_id)
                this.$set(this.meterList2[index], 'customer_type', customer_type)
                return
            }
            if (e === '') {
                e = null
            }
            this.$set(this.meterList2[index], key, e)
            if (key == 'quantity') {
                // 计算库存数量
                let total = 0
                this.meterList2.forEach(item => {
                    if (item.product_name == product_name && item.product_model_code == product_code && item.identification == identification) {
                        total = total * 1 + item.quantity * 1
                    }
                })

                // 上方数量
                this.meterList.forEach(item => {
                    if (item.product_name == product_name && item.product_model_code == product_code && item.identification == identification) {
                        // if (total > item.quantity) {
                        //     this.$Message.warning(`审核数量大于单据数量`)
                        //     return
                        // } else if (total < item.quantity) {
                        //     this.$Message.warning(`审核数量小于单据数量`)
                        //     return
                        // } else {

                        // }
                        item.receipt_quantity = total
                    }
                })
            }
        },
        // 获取供应商下拉
        getSupple() {
            // 获取供应商下拉
            this.$http.get(this.$apiConsign.getSupplierNameList).then(res => {
                if (res.status) {
                    this.supplierSelect = res.data
                }
            })
        },
        // 删除数据
        deleteDate(row, index) {
            this.meterList2.splice(index, 1)
            for (var i = 0; i < this.meterList.length; i++) {
                if (this.meterList[i].product_name == row.product_name && this.meterList[i].product_model_code == row.product_model_code && this.meterList[i].identification == row.identification) {
                    this.meterList[i].receipt_quantity = (this.meterList[i].receipt_quantity * 1 - row.quantity * 1) >= 0 ? (this.meterList[i].receipt_quantity * 1 - row.quantity * 1) : 0
                }
            }
        },
        // 同步数据
        synchronization(row, index) {
            // this.meterList[index].receipt_quantity = row.quantity 
            let clonRow = JSON.parse(JSON.stringify(row))
            clonRow.quantity = 0
            this.meterList2.push(clonRow)
        },
        // 动态表格
        sureSetup(val) {
            this.queryOption()
        },
        // 获取表格表头
        queryOption() {
            this.$http
                .get(this.$apiConsign.systemOptionItem, { option_page: this.option_page }, true)
                .then(res => {
                    this.titleList = res.data.enable
                    localStorage.setItem('adminTtitleList', JSON.stringify(res.data.list))
                    for (let i = 0; i < this.titleList.length; i++) {
                        if (this.titleList[i].show) {
                            this.titleList[i].key = this.titleList[i].data_col
                            this.titleList[i].width = this.titleList[i].column_width
                            this.titleList[i].align = 'center'
                            this.titleList[i].resizable = true
                            if (this.titleList[i].title === '供应商' && this.order_type == 1) {
                                this.titleList[i].slot = 'supplier_id'
                            }
                            if (this.titleList[i].title === '客户名称' && this.order_type == 2) {
                                this.titleList[i].slot = 'customer_name'
                            }
                            if (this.titleList[i].title === '批号' && this.order_type == 1) {
                                this.titleList[i].slot = 'batch_number'
                            }
                            if (this.titleList[i].title === '生产日期') {
                                this.titleList[i].slot = 'production_date'
                            }
                            if (this.titleList[i].title === '有效期') {
                                this.titleList[i].slot = 'valid_period'
                            }
                            if (this.titleList[i].title === '数量') {
                                this.titleList[i].slot = 'quantity'
                            }
                            if (this.titleList[i].title === '序列号') {
                                this.titleList[i].slot = 'serial_number'
                            }
                            if (this.titleList[i].title === '采购单价' && this.order_type == 1) {
                                this.titleList[i].slot = 'unit_price'
                            }
                            if (this.titleList[i].title === '销售单价' && this.order_type == 2) {
                                this.titleList[i].slot = 'sale_unit_price'
                            }
                        }
                    }
                    this.stayListColumns = []
                    this.stayListColumns.unshift({
                        title: '序号',
                        type: 'index',
                        align: 'center',
                        width: 80,
                    })

                    this.stayListColumns = [...this.stayListColumns, ...this.titleList]
                    this.stayListColumns = JSON.parse(JSON.stringify(this.stayListColumns))
                    this.stayListColumns.push({
                        title: '操作',
                        align: 'center',
                        width: 200,
                        fixed: 'right',
                        render: (h, param) => {
                            return h('div', [
                                h(
                                    'span',
                                    {
                                        style: {
                                            color: '#389AFC',
                                            cursor: 'pointer',
                                        },
                                        on: {
                                            click: () => {
                                                this.deleteDate(param.row, param.index)

                                            },
                                        },
                                    },
                                    '删除'
                                ),
                            ])
                        },
                    })
                    // 让供应商和客户放在最前面
                    let index2 = 0
                    let index1 = 1
                    if (this.order_type == 1) {
                        this.stayListColumns.forEach((item, index) => {
                            let keys = Object.keys(item)
                            keys.forEach(key => {
                                if (item['data_col'] == 'supplier_id') {
                                    index2 = index
                                }
                            })
                        })
                    } else {
                        this.stayListColumns.forEach((item, index) => {
                            let keys = Object.keys(item)
                            keys.forEach(key => {
                                if (item['data_col'] == 'customer_name') {
                                    index2 = index
                                    console.log(key, index2)
                                }
                            })
                        })
                    }
                    let temp = this.stayListColumns[index1];
                    this.stayListColumns[index1] = this.stayListColumns[index2];
                    this.stayListColumns[index2] = temp;
                    // this.stayListColumns =  this.stayListColumns.splice(index2,1,...this.stayListColumns.splice(index1, 1 , this.stayListColumns[index2]))
                })
        },
        // 返回
        back() {
            this.$router.go(-1)
        },
        // 获取报溢报损同步信息
        queryInventoryDetail() {
            this.$http.get(this.$apiConsign.SpilloverOrderDetail, { spillover_order_number: this.id }, true).then(res => {
                this.meterList = res.data.line_info
                this.detailsInfo = res.data
                // 时间处理
                if (this.meterList.length != 0) {
                    let keys = Object.keys(this.meterList[0])
                    this.meterList.forEach((item,index) => {
                        // 给每条数据做一个标识
                        this.$set(item,'identification',index)
                        // 默认入库数量都为0
                        this.$set(item, 'receipt_quantity', 0)
                        // 时间处理
                        keys.forEach(key => {
                            if (typeof item[key] === 'number' && item[key].toString().length === 10) {
                                if (key == 'production_date' || key == 'valid_period') {
                                    item[key] = item[key] ? this.$moment(item[key] * 1000).format('YYYY-MM-DD HH:mm:ss') : null
                                    item[key] = item[key] ? this.$utils.Timetransformation(item[key]).replace(/-/gi, '') : null
                                } else {
                                    item[key] = this.$moment.unix(item[key]).format('YYYY-MM-DD')
                                }

                            }
                        })
                    })
                }


            })
        },

        // 提交
        comfiy() {
            // 前端验证
            if (this.meterList2.length == 0) {
                this.$Message.warning(`请至少选择一项产品`)
                return
            }
            let errArr = []
            this.meterList2.forEach((item,index)=>{
              let res = this.$utils.dataType(item.valid_period)
              let res2 = this.$utils.dataType(item.production_date)
              if(res.code){
                errArr.push(res.text)
              }
              if(res2.code){
                errArr.push(res2.text)
              }
            })
            if(errArr.length){
              this.$Message.warning(errArr[0])
              return
            }
            // 数量必须等于单据数量才可以审核
            for (let i = 0; i < this.meterList.length; i++) {
                let total = 0
                for (let j = 0; j < this.meterList2.length; j++) {
                    if (this.meterList2[j].product_name == this.meterList[i].product_name && this.meterList2[j].product_model_code == this.meterList[i].product_model_code && this.meterList2[j].identification == this.meterList[i].identification) {
                        total = total * 1 + this.meterList2[j].quantity
                        this.meterList[i].numTotal = total
                    }
                }
            }
            // 提示
            for (let k = 0; k < this.meterList.length; k++) {
                if (this.meterList[k].quantity > this.meterList[k].numTotal) {
                    this.$Message.warning(`审核数量小于单据数量`)
                    return
                } else if (this.meterList[k].quantity < this.meterList[k].numTotal) {
                    this.$Message.warning(`审核数量大于单据数量`)
                    return
                }
            }
            for (let i = 0; i < this.meterList2.length; i++) {
                if (!this.meterList2[i].supplier_id && this.order_type == 1) {
                    this.$Message.warning(`请选择${i + 1}条信息的供应商`)
                    return
                }
                if (!this.meterList2[i].customer_id && !this.meterList2[i].customer_type && this.order_type == 2) {
                    this.$Message.warning(`请选择${i + 1}条信息客户信息`)
                    return
                }
                if (this.meterList2[i].unit_price === null  && this.order_type == 1) {
                    this.$Message.warning(`请选择${i + 1}条数据的采购金额`)
                    return
                }
                if (this.meterList2[i].sale_unit_price === null && this.order_type == 2) {
                    this.$Message.warning(`请选择${i + 1}条数据的销售金额`)
                    return
                }
                if (!this.meterList2[i].valid_period) {
                    this.$Message.warning(`请填写${i + 1}条信息有效期`)
                    return
                }
                if (!this.meterList2[i].batch_number) {
                    this.$Message.warning(`请填写${i + 1}条信息批号`)
                    return
                }
                if (!this.meterList2[i].quantity) {
                    this.$Message.warning(`请填写${i + 1}条数量`)
                    return
                }
            }

            let obj = {
                spillover_order_number: this.detailsInfo.spillover_order_number,
                warehouse_id: this.detailsInfo.warehouse_id,
                warehouse_name: this.detailsInfo.warehouse_name,
                line_info: this.meterList2
            }
            this.$http.post(this.$apiConsign.SpilloverOrderManage, obj, true).then(res => {
                this.$Message.success('提交成功')
                sessionStorage.setItem('updataCache', '0')
                this.$router.go(-1)
            })
        }
    },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    // overflow-y: auto;
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.marginLeft20 {
  margin-left: 20px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iviewIptWidth270 {
  width: 290px !important;
}
.titleTxt {
  font-size: 16px;
  font-weight: 500;
  color: #525b6d;
  display: inline-block;
  margin: 15px 0px;
}
.picking {
  text-align: center;
  color: #ccc;
}
.inputFlex {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/ .ivu-table-wrapper {
  overflow: inherit;
}
</style>
